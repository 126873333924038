import {
  Injectable,
  signal,
  ApplicationRef,
  inject,
  ComponentRef,
  createComponent,
  Type,
} from "@angular/core";
import { AlertTypeMobileSelectionComponent } from "../containers/alert-type-mobile-selection/alert-type-mobile-selection.component";
import { routes } from "../../../../environments/environment.routes";
import { Router } from "@angular/router";
import { AlertType } from "../utils/alert.enum";
import { MessageService } from "primeng/api";
import { Reason } from "../models/reason.model";
import { Vendor } from "../models/vendor.model";
import { ALERT_TYPE_SELECTION_COMPONENT_KEY } from "../utils/alerts.constants";
import { Category } from "../models/category.model";

export interface BaseSidebarConfig<T> {
  onHide?: (instance: T) => void;
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const serviceReasonOptions: Reason[] = [
  { name: "Order tank refill - only if under 250 PSI", id: "1" },
  { name: "New Tank Order", id: "2" },
  { name: "Other", id: "3" },
];

const equipmentRepairReasonOptions: Reason[] = [
  { name: "Clean freezer before repair. Store responsible", id: "1" },
  { name: "Defrost - Bunker", id: "2" },
  { name: "Defrost/Ice Build up", id: "3" },
  { name: "Frz/not holding temp", id: "4" },
];

const creditReasonOptions: Reason[] = [
  { name: "Credit Reason 1", id: "1" },
  { name: "Credit Reason 2", id: "2" },
  { name: "Credit Reason 3", id: "3" },
];

const vendorOptions: Vendor[] = [
  { name: "Vendor 1", id: "1" },
  { name: "Vendor 2", id: "2" },
  { name: "Vendor 3", id: "3" },
  { name: "Vendor 4", id: "4" },
];

const categoryOptions: Category[] = [
  { name: "Category 1", id: "1" },
  { name: "Category 2", id: "2" },
  { name: "Category 3", id: "3" },
  { name: "Category 4", id: "4" },
];

@Injectable({
  providedIn: "root",
})
export class AlertsService {
  readonly stepHeader = signal("Select an alert type");
  readonly serviceReasonOptions = signal<Reason[]>(serviceReasonOptions);
  readonly vendorOptions = signal<Vendor[]>(vendorOptions);
  readonly categoryOptions = signal<Category[]>(categoryOptions);
  readonly creditReasonOptions = signal<Reason[]>(creditReasonOptions);
  readonly equipmentRepairReasonOptions = signal<Reason[]>(
    equipmentRepairReasonOptions,
  );
  private activeComponents = new Map<string, ComponentRef<unknown>>();

  private appRef = inject(ApplicationRef);
  private router = inject(Router);
  private message = inject(MessageService);

  createAlert(): void {
    this.message.add({
      severity: "success",
      summary: "Alert Created!",
      detail: "Alert has succesfully been created.",
    });
    this.router.navigate([routes.frontend.alerts]);
    this.resetAlertsState();
  }

  resetAlertsState(): void {
    this.stepHeader.set("Select an alert type");
  }

  openSidebarComponent<T>(
    componentKey: string,
    component: Type<T>,
    config: BaseSidebarConfig<T> = {},
  ): void {
    if (this.activeComponents.has(componentKey)) return;

    const componentRef = this.createSidebarComponent(
      component as Type<object>,
      {
        ...config,
        onHide: () => {
          if (config.onHide) {
            config.onHide(componentRef.instance as T);
          }
          this.destroySidebarComponent(componentRef);
          this.activeComponents.delete(componentKey);
        },
      },
    );

    this.activeComponents.set(componentKey, componentRef);
  }

  openAlertTypeMobileSelectionSidebar(): void {
    this.openSidebarComponent(
      ALERT_TYPE_SELECTION_COMPONENT_KEY,
      AlertTypeMobileSelectionComponent,
      {},
    );
  }

  navigateToMobileAlertCreationFlow(alertType: AlertType) {
    let route = "";

    switch (alertType) {
      case AlertType.NeedMoreProduct:
        route = routes.frontend.mobileNeedMoreProductIssue;
        break;
      case AlertType.ServiceIssue:
        route = routes.frontend.mobileServiceIssue;
        break;
      case AlertType.EquipmentIssue:
        route = routes.frontend.mobileEquipmentIssue;
        break;
      case AlertType.CreditIssue:
        route = routes.frontend.mobileCreditIssue;
        break;
    }

    this.router.navigate([routes.frontend.alerts, route]);
  }

  private createSidebarComponent<T extends object>(
    component: Type<T>,
    config: Partial<T> = {},
  ): ComponentRef<T> {
    const componentRef = createComponent(component, {
      environmentInjector: this.appRef.injector,
    });

    this.appRef.attachView(componentRef.hostView);
    document.body.appendChild(componentRef.location.nativeElement);
    document.body.classList.add("no-scroll");

    Object.assign(componentRef.instance as T, {
      visible: true,
      ...config,
    });

    return componentRef;
  }

  private destroySidebarComponent(componentRef: ComponentRef<unknown>): void {
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
    document.body.classList.remove("no-scroll");
  }
}
